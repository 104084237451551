import React from "react";
import { Component } from "react";

import axios from "axios";

class MobilePreview extends Component {
  state = {
    editorState: "",
    errorMessage: "",
  };
  componentDidMount() {
    // this.terms();
  }
  terms = () => {
      // const article = { title: "React POST Request Example" };
    axios
      .get("")
      .then((res) => {
        if (res.data.status_code == 200 && res.data.Status == "Success") {
          console.log("response", res.data.Terms_Description);

          this.setState({
            editorState: res.data.Terms_Description,
          });
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <div
          className="subcontainer"
          style={{ paddingTop: "7em", width: "80%", margin: "0 auto" }}
        >
          <div style={{ color: "black" }}>
            Liability Waiver <br />
            Read this agreement carefully before clicking ‘I agree.’ Clicking
            this button indicates you understand it and agree on its terms. By
            agreeing to these terms, you are giving up certain legal rights,
            including the right to sue or recover damages in case of injury,{" "}
            <br />
            death or property damages, for any reason connected to the use of
            any features within the National Equine Emergency Duress System.
            <br />
            <div style={{ marginTop: "10px" }} />I acknowledge that handling
            horses is an inherently dangerous activity and involves risks that
            may cause serious injury to both horse and/or handler and in some
            cases death, because of the unpredictable nature and irrational
            behaviour of horses, regardless of their training and past
            performance, particularly during the pressure of an emergency. I
            acknowledge that any engagement I make with the National Equine
            Emergency Duress System’s features, I do so by my own choice and
            acknowledge the risks involved. I voluntarily assume the risk and
            danger of injury or death inherent in the involvement of entering a
            high-risk environment such as an area impacted by natural disaster
            events, or other high-risk scenarios. <br />I acknowledge that these
            terms and conditions form a contract and I agree that if a lawsuit
            is filed against Safety And Risk Intelligence or its owner, agents,
            partners or employees for any injury, damage or death incurred as a
            result of engaging with the National Equine Emergency Duress
            System’s features, I will pay all attorney’s fees and costs incurred
            by Safety And Risk Intelligence in defending such an action. <br />
            <div style={{ marginTop: "10px" }} />
            Acceptable Use and Termination <br />
            Services provided by us may only be used for lawful purposes. You
            agree to comply with all applicable laws, rules and regulations in
            connection with your use of the services. Any material or conduct
            that in our judgement violates this policy in any manner may result
            in suspension or termination of the services or removal of the
            user’s account with or without notice.
            <br />
            <div style={{ marginTop: "10px" }} />
            Trademarks and Copyrights <br />
            All rights are reserved, relating to the intellectual property
            contained within this mobile application, patent pending No. (TBA).
            EQ Duress is copyright to Safety And Risk Intelligence.
            <br />
            PRIVACY POLICY
            <br />
            <div>1. We respect your privacy </div>
            <div>
              1.1 Safety And Risk Intelligence respects your right to privacy
              and is committed to safeguarding the privacy of our customers and
              software application users. We adhere to the National Privacy
              Principles established by the Privacy Act 1988 (Cth). This policy
              sets out how we collect and treat your personal information.{" "}
            </div>
            <div>
              1.2 ‘Personal information’ is information we hold which is
              identifiable as being about you.
            </div>{" "}
            <br />
            <div>
              2. Collection of personal information 2.1Safety And Risk
              Intelligence will, from time-to-time, receive and store personal
              information you enter onto our software application National
              Equine Emergency Duress System, provide to use directly or give us
              in other forms.
            </div>{" "}
            <div>
              2.2 You may provide basic information such as your name, phone
              number, address and email address to enable us to send
              information, provide updates and process your product or service
              order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you
              provide information about your personal or business affairs,
              change your content or email preference, respond to surveys and/or
              promotions, provide financial or credit card information, or
              communicate with our customer support.{" "}
            </div>
            <div>
              2.3 Additionally, we may collect any other information you provide
              whilst interacting with us.
            </div>{" "}
            <br />
            <div>3. How we collect your personal information</div>
            <div>
              3.1 Safety And Risk Intelligence collects personal information
              from you in a variety of ways, including when you interact with us
              electronically or in person, when you access our software
              application and when we provide our services to you. We may
              receive personal information from third parties. If we do, we will
              protect it as set out in this Privacy Policy.
            </div>
            <br />
            <div>4. Use of your personal information</div>
            <div>
              4.1 Safety And Risk Intelligence may use personal information
              collected from you to provide you with information, updates about
              our services. We may also make you aware of new and additional
              products, services and opportunities available to you. We may use
              your personal information to improve our products and services and
              better understand your needs.
            </div>
            <div>
              4.2 The National Equine Emergency Duress System application may
              make third party social media features available to its users. We
              cannot ensure the security of any information you choose to make
              public in a social media feature. Also, we cannot ensure that
              parties who have access to such publicly available information
              will respect your privacy. Safety And Risk Intelligence may
              contact you by a variety of measures including, but not limited to
              telephone, email, SMS or mail.
            </div>
            <br />
            <div>5. Disclosure of your personal information</div>
            <div>
              5.1 We may disclose your personal information to any of our
              employees, officers, insurers, professional advisers, suppliers or
              subcontractors insofar as reasonably necessary for the purposes
              set out in this policy. Personal information is only supplied to a
              third party when it is required for the delivery of our services.
            </div>
            <div>
              5.2 We may from time-to-time, need to disclose personal
              information to comply with a legal requirement, such as a law,
              regulation, court order, subpoena, warrant, in the course of a
              legal proceeding or in response to a law enforcement agency
              request.
            </div>
            <div>
              5.3 We may also use your personal information to protect the
              copyright, trademarks, legal rights, property or safety of Safety
              And Risk Intelligence, its application, website and customers or
              third parties.
            </div>
            <div>
              5.4 Information that we collect may from time-to-time be stored,
              processed in or transferred between parties located outside of
              Australia.
            </div>
            <div>
              5.5 If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases. This information may be disclosed by a potential
              purchaser under an agreement to maintain confidentiality. We would
              seek to only disclose information in good faith and where required
              by any of the above circumstances.
            </div>
            <div>
              5.6 By providing us with personal information, you consent to the
              terms of this Privacy Policy and the types of disclosure covered
              by this policy. Where we disclose your personal information to
              third parties, we will request that the third party follow this
              policy regarding handling your personal information.
            </div>
            <br />
            <div>6. Security of your personal information</div>
            <div>
              6.1 Safety And Risk Intelligence is committed to ensuring that the
              information you provide to use is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification and disclosure.{" "}
            </div>
            <div>
              6.2 The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us, or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information we
              collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.{" "}
            </div>
            <br />
            <div>7. Access to your personal information </div>
            <div>
              7.1 You may request details of personal information that we hold
              about you in accordance with the provisions of the Privacy Act
              1988 (Cth). A small administrative fee may be payable for the
              provision of information. If you would like a copy of the
              information we hold about you or believe that any information we
              hold on you is inaccurate, out of date, incomplete, irrelevant or
              misleading, please email us at need@sarintel.com.au .
            </div>{" "}
            <div>
              7.2 We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act.{" "}
            </div>
            <br />
            <div>8. Complaints about privacy practices </div>
            <div>
              8.1 If you have any complaints about our privacy practices, please
              feel free to send in details of your complaints to PO Box 246
              Kangaroo Ground, Victoria, 3097. We take complaints very seriously
              and will respond shortly after receiving written notice of your
              complaint.{" "}
            </div>
            <br />
            <div>9. Opt out right </div>
            <div>
              9.1 You can stop all collection of information by the National
              Equine Emergency Duress System application easily, by uninstalling
              the application. You may use the standard uninstall processes as
              may be available as part of your mobile device or via the mobile
              application marketplace or network. You can also request to
              opt-out via email, at need@sarintel.com.au.{" "}
            </div>
            <br />
            <div>10.Changes to Privacy policy </div>
            <div>
              10.1 Please be aware that we may change this Privacy Policy in the
              future. We may modify this policy at any time, at our sole
              discretion and all modifications will be effective immediately
              upon our positing of the modifications on our website or
              application. Please check back from time-to-time to review our
              Privacy Policy.{" "}
            </div>
            <br />
            <div>11.Software Application </div>
            <div>
              11.1 When you use our application When you use our application we
              may collect certain information such as mobile unique device IS,
              the IP address of your mobile device, mobile operating system, the
              type of mobile internet browsers you use, and the information
              about the way you use the application. This information is used in
              an aggregated manner to analyse how people use our site, such that
              we can improve our service.{" "}
            </div>
            <div>
              11.2 Cookies We may from time-to-time use cookies on our software
              application. Cookies are very small files which a website uses to
              identify you when you come back to the application and to store
              details about your use of the application. Cookies are not
              malicious programs that access or damage your computer, tablet or
              smartphone. Most devices automatically accept cookies but you can
              choose to reject cookies by changing your device settings.
              However, this may prevent you from taking full advantage of our
              application.{" "}
            </div>
            <div>
              11.3 Automatic collection The software application may collect
              certain information automatically, including but not limited to,
              the type of mobile device you use, your mobile device unique
              device ID, the IP address of your mobile device, your mobile
              operating system, the type of internet browsers you use, and
              information about the way you use the application.
            </div>{" "}
            <div>
              11.4 Third parties Our software application may from time-to-time
              have links to other applications or websites not owned or
              controlled by us. These links are meant for your convenience only.
              Links to third party applications and websites do not constitute
              sponsorship or endorsement or approval of these third parties.
              Please be aware that Safety And Risk Intelligence is not
              responsible for the privacy practises of other such applications
              or websites. We encourage our users to be aware, when they leave
              our application or website, to read the privacy statements of each
              and every application or website that collects personal
              identifiable information.{" "}
            </div>
            <div>
              11.5 Geo-location When you visit the mobile application, we may
              use GPS technology (or other similar technology) to determine your
              current location in order to determine the area you are located
              within. Your geo-located position and other personal information
              will be shared with other application users, upon your activation
              of the EQ Duress feature, for emergency assistance purposes. Your
              local area and phone number will be identified whilst using other
              features of the application, including ShowHorse Marketplace,
              Vetfind and Equine Services Directory. We will not share your
              location with other users or partners outside of these reasons
              permitted by you.
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default MobilePreview;
