import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import Preview from '../src/main/components/home/Editor/MobilePreview';
// import RedeemCode from './main/components/home/RedeemCode.js'

const MainComponent = lazy(() => import("./main/components/Main"));

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Switch>
            <Route path="/c">{/* <LoggedInComponent /> */}</Route>
            <Route path="/terms" component={Preview} />
            <Route>
              <MainComponent />

              {/* <RedeemCode />              */}
            </Route>
            {/* <Route exact path ='/redeemcode' component={RedeemCode} /> */}
          </Switch>
        </Suspense>
      </MuiThemeProvider>
      {/* <Route path="/terms" component={Preview} /> */}
    </BrowserRouter>
  );
}

serviceWorker.unregister();

export default App;
